import React, { useState, useEffect } from 'react'
import { Collapse } from 'reactstrap'
import MarkdownToHtml from '../MarkdownToHtml'
import { ClickableIcon, QuestionText, QuestionWrapper, AnswerWrapper, ItemWrapper, MainWrapper } from './styled'

export const Question = ({ item, id, isCollapsed, setCollapse }) => (
  <QuestionWrapper onClick={() => setCollapse(!isCollapsed)} {...{ isCollapsed }} id={item.question}>
    <QuestionText>{item.question}</QuestionText>
    {
      isCollapsed
        ? <ClickableIcon onClick={() => setCollapse(!isCollapsed)} className='fa fa-angle-up' />
        : <ClickableIcon onClick={() => setCollapse(!isCollapsed)} className='fa fa-angle-down' />
    }
  </QuestionWrapper>
)

export const Answer = ({ item, id, isCollapsed }) => (
  <Collapse isOpen={isCollapsed}>
    <AnswerWrapper>
      <MarkdownToHtml content={item.answer_markdown} />
    </AnswerWrapper>
  </Collapse>
)

export const AccordionFaqItem = ({ item, id, activeId }) => {
  const [isCollapsed, setCollapse] = useState(false)

  useEffect(() => {
    if (activeId) {
      if (activeId === item.question) {
        setCollapse(true)
      }
    }
  }, [activeId])
  return (
    <ItemWrapper>
      <Question {...{ item, id, isCollapsed, setCollapse }} />
      <Answer {...{ item, id, isCollapsed }} />
    </ItemWrapper>
  )
}

export default ({ items, activeId }) => (
  <MainWrapper>
    {items.map((item, id) => <AccordionFaqItem {...{ item, id, activeId }} key={id} />)}
  </MainWrapper>
)
