import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { isEmpty } from 'ramda'
import Layout from '../components/layout'
import Accordion from '../components/Accordion'
import AccordionFaq from '../components/AccordionFaq'
import { Heading } from '../components/Typography'
import { processEdges } from '../utils/layouts'

export default ({ pageContext, location, data }) => {
  const { category, html, frontmatter, slug } = pageContext
  if (!data.allMarkdownRemark || isEmpty(data.allMarkdownRemark)) return <p>No data available.</p>
  const { edges } = data.allMarkdownRemark
  const subCategoryList = processEdges(edges)

  const [activeId, setActiveId] = useState(null)
  
  useEffect(() => {
    if (location.hash) {
      const encodedId = location.hash.split('#')[1]
      if (encodedId) {
        let id = window.decodeURI(encodedId)
        if (id[id.length - 1] !== '?') {
          id = `${id}?`
        }
        const { question } = frontmatter.questions.find(o => o.question.toLowerCase() === id.toLowerCase()) || {}
        const el = document.getElementById(question)
        el && el.scrollIntoView()
        setActiveId(question)
      }
    }
  }, [])

  return (
    <Layout {...{ location }}>
      <div className='container'>
        <div className='py-3'>
          <div className='row'>
            <div className='col-lg-4'>
              <Accordion parent={category} items={subCategoryList} slug={slug} />
            </div>
            <div className='col-lg-8'>
              <div className='layout-sub-category'>
                <Heading>{frontmatter.title}</Heading>
                <div dangerouslySetInnerHTML={{ __html: html || '' }} />
                {frontmatter.questions ? <AccordionFaq items={frontmatter.questions} activeId={activeId} /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($categoryRegex: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: {
            regex: $categoryRegex
          }
        }
      }
    ) {
      edges {
        node {
          ...MarkdownFrontmatter
        }
      }
    }
  }
`
