import styled from 'styled-components'
import { Theme } from '@pga/pga-component-library'

export const ClickableIcon = styled.i`
  padding: 1rem;
  margin: -1rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
`

export const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: ${props => props.isCollapsed ? 'rgba(0,0,0,.03)' : 'white'};
  border-bottom: 1px solid rgba(0,0,0,.125);
  cursor: pointer;
`
export const AnswerWrapper = styled.div`
  padding: 1.25rem;
`
export const QuestionText = styled.p`
  margin: 1rem 0;
  font-weight: 600;
`
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  line-height: 1.5;
  box-shadow: 0 5px 10px 0 ${Theme.colors.primaryShadow};
`

export const MainWrapper = styled.div`
  margin-bottom: 1.5rem;
`
